import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import { Helmet } from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const PolicyPage = props => {
  const { title, body } = props.data.policies.nodes[0];
  const { storeName, storeDescription } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  const { location } = props;
  return (
    <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300 }}>
      <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        description={storeDescription}
        openGraph={{
          url: location.href,
          title: `${title} — ${storeName}`,
          description: storeDescription,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false}>
        <meta name="description" content={title} />
      </Helmet>
      <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
        {title}
      </Heading>
      <Box dangerouslySetInnerHTML={{ __html: body }}></Box>
    </Flex>
  );
};

export default PolicyPage;
